body {
  height: 100% !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
.swal2-height-auto {
  height: 100% !important;
}

#root,
#root > div,
.app {
  height: 100%;
}

.loading-page {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #00ab50;
  z-index: 10000;
  transition: all 0.5s linear;
}

.loading-page img {
  width: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.loading-page .spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.loading-page.hide-loading {
  height: 0 !important;
}
.header {
  background-color: #00ab50;
  box-shadow: inset -1px 1px 5px rgba(0, 0, 0, 0.2);
}

.aside {
  background-color: #ffffff;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
}
.brand {
  background: #ffffff; /* fallback for old browsers */

  /* background-image: url(https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/pattern-dashboard.png); */
  height: 119px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.menu-nav {
  background-color: #ffffff;
}
.menu-item i,
.menu-item .menu-text {
  color: #6d6d6d !important;
}
.menu-item:hover i,
.menu-item:hover .menu-text {
  color: #00ab50 !important;
}
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: #ffffff;
}
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading,
.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link {
  background-color: #ffffff;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover
  > .menu-link {
  background-color: #f7f7f7;
}
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active)
  > .menu-heading,
.aside-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active)
  > .menu-link {
  background-color: #fdfdfd;
}
.text-dark {
  color: #6d6d6d !important;
}
.btn-indicacao {
  border-color: #ff9701;
  border-color: #ff9701 !important;
  background: -webkit-linear-gradient(left, #ffba1a, #ff9701);
  background: -o-linear-gradient(right, #ffba1a, #ff9701);
  background: -moz-linear-gradient(right, #ffba1a, #ff9701);
  background: linear-gradient(to right, #ffba1a, #ff9701);
}

.btn-indicacao:hover {
  /*background: #28493d;*/
  border-color: #ffba1a;
  background: -webkit-linear-gradient(left, #ff9701, #ffba1a);
  background: -o-linear-gradient(right, #ff9701, #ffba1a);
  background: -moz-linear-gradient(right, #ff9701, #ffba1a);
  background: linear-gradient(to right, #ff9701, #ffba1a);
}

.indicacao {
  background-color: #00ab50;
  border: solid 1px transparent;
  border-radius: 30px;
  height: 100%;
  position: relative;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.indicacao img {
  margin-top: 20px;
  min-width: 100%;
}

.indicacao ul {
  color: white;
  font-size: 15px;
  margin: 20px 35px 20px auto;
  text-align: justify;
  list-style-type: none;
}

.indicacao li {
  background: url("https://zenbytes-public.sfo2.digitaloceanspaces.com/ribercred/arrow.png")
    no-repeat 0px 8px;
  padding: 0px 0 6px 12px;
}

.topbar span {
  color: #ffffff !important;
}
.symbol.symbol-success .symbol-label {
  background-color: #f7931d;
}
.btn.btn-clean.focus:not(.btn-text),
.btn.btn-clean:focus:not(.btn-text),
.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: transparent;
}
.btn.btn-light-primary {
  background-color: #e7f3ed;
}
.modal .modal-header .close span {
  display: inline;
}
.required {
  color: #dc3545 !important;
}
.form-control:focus {
  background-color: #ffffff;
  border-color: #00ab50;
  outline: 0;
}
.form-control,
.input-group-btn button {
  border-radius: 0 !important;
}
.input-group i {
  margin-left: 5px;
}
.btn-ramos {
  width: 100%;
  text-align: left;
  border-radius: 0 !important;
  border: 1px solid #e4e6ef;
  color: #3f4254;
  height: calc(1.5em + 1.3rem + 2px);
}
.btn-ramos::after {
  position: absolute !important;
  right: 10px;
  top: 16px;
}
.ramos .dropdown-menu {
  width: 100%;
  border-radius: 0 !important;
  box-shadow: none;
  border: 1px solid #00ab50;
  top: -2px !important;
}
.show.dropdown .btn-ramos {
  border-color: #00ab50;
}
.ramos__item img {
  height: 30px;
}
.ramos__selected-item img {
  height: 20px;
}
.ramos__item span,
.ramos__selected-item span {
  align-self: center;
  padding-left: 10px;
}

.form-group label {
  font-weight: 700;
}
.custom-file-input {
  cursor: pointer;
}
.custom-file-input ~ .custom-file-label::after {
  font-family: "Font Awesome 5 Free";
  content: "\f093" !important;
  display: inline-block;
  vertical-align: middle;
  font-weight: 900;
  border-radius: 0;
}
.custom-file-label {
  overflow: hidden;
  white-space: nowrap;
  border-radius: 0;
  font-weight: 400 !important;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #00ab50;
}
.swal2-styled.swal2-confirm {
  background-color: #00ab50 !important;
  border-left-color: #00ab50 !important;
  border-right-color: #00ab50 !important;
}
.rounded-pill {
  border-radius: 50rem !important;
}
.card-header {
  padding: 1.5rem 1.75rem;
}
.card-header h3 {
  margin: 0 !important;
}
.Mui-checked .MuiTouchRipple-root,
.Mui-checked .MuiSwitch-thumb {
  color: #47ff4f;
}
.MuiTouchRipple-root,
.MuiSwitch-thumb {
  color: #f73737;
}
.MuiSwitch-track {
  background-color: rgba(0, 0, 0, 0.8) !important;
}
.alias-button,
.alias-button:hover,
.alias-button:active,
.alias-button:focus,
.alias-button:visited,
.show > .alias-button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  cursor: pointer;
  outline: inherit;
}
.btn i {
  padding-left: 4px;
}
.btn-status {
  color: #ffffff !important;
  border-radius: 3px;
  cursor: auto !important;
}
.realizarContato {
  background: #00858b;
  color: #ffffff;
}

.emAtendimento {
  background: #096d31;
  color: #ffffff;
}

.emCotacao {
  background: #b3294f;
  color: #ffffff;
}

.emEfetivacao {
  background: #2429c5;
  color: #ffffff;
}

.apolice {
  background: #faa01e;
  color: #ffffff;
}

.fechado {
  background: #1bb394;
  color: #ffffff;
}

.agendado {
  background: #d6c701;
  color: #ffffff;
}

.agger {
  background: #e40bc7;
  color: #ffffff;
}

.pendente {
  background: #fdff96;
  color: #000 !important;
}

.pParcela {
  background: #1f2a64;
  color: #ffffff;
}

.bloqueado {
  background: #245000;
}

.emAnalise {
  background: #004552;
}

.aguardandoDoc {
  background: #22263a;
}

.perdido {
  background: #fb0004;
  color: #ffffff;
}

.triagem {
  background: #263c83;
}

.aguardando {
  background: #faa01e;
  color: #ffffff;
}

.emissao {
  background: #00858b;
  color: #ffffff;
}

.arquivado {
  background: #810003;
  color: #ffffff;
}

.aguardandoAssinatura {
  background: #553700;
}

.table-hover tbody tr:hover {
  color: #3f4254;
  background-color: #f7f7f7;
}
.kt-datatable__cell .dropdown-toggle.btn:after {
  content: none;
}
.table-dropdown-toggle {
  color: #595d6e;
  font-weight: 500;
}
.table-dropdown-toggle::after {
  display: none !important;
}
.dropdown-filter {
  top: 9px !important;
}
.input-filter {
  padding: 0 11px;
}
.input-filter button i {
  color: #ffffff;
}
.filtered i {
  color: #ffc107 !important;
}
.filtered span {
  color: #00ab50 !important;
}
.kt-datatable__cell .kt-nav {
  min-width: 200px;
}
.MuiTab-textColorPrimary.Mui-selected {
  color: #00ab50 !important;
}
.MuiTouchRipple-root {
  color: #00ab50 !important;
}
.MuiTabs-indicator {
  background-color: #00ab50 !important;
}
th span {
  font-weight: 700 !important;
}
input[type="checkbox"]:after {
  background-color: red !important;
}
.kt-datatable__row--striped {
  text-decoration: line-through;
}
@media (max-width: 991.98px) {
  .topbar {
    background-color: #00ab50;
  }
}
.footer {
  display: flex;
  justify-content: space-between;
}
.burger-icon span,
.burger-icon span::before,
.burger-icon span::after {
  background-color: #ffffff;
}
.burger-icon:hover span,
.burger-icon:hover span::before,
.burger-icon:hover span::after {
  background-color: #ffffff;
}

@media (min-width: 991.98px) {
  .burger-icon {
    display: none !important;
  }
}
.header-table {
  white-space: normal !important;
  text-align: left;
}
.checklist {
  padding: 5px 12px;
  max-height: 300px;
  overflow-y: auto;
  position: relative;
}
.checklist-close {
  position: absolute;
  right: 19px;
  top: 16px;
}

.checklist::-webkit-scrollbar {
  width: 5px;
}
.checklist::-webkit-scrollbar-thumb {
  background-color: #00ab50;
}
.checklist::-webkit-scrollbar-thumb:hover {
  background-color: #67be9a;
}
.checklist-filter {
  padding-top: 10px;
  border-bottom: 1px solid #ebedf3;
}
.checklist-filter label {
  cursor: pointer !important;
}
.checklist-filter label span {
  margin-left: 8px;
  font-weight: 500 !important;
}
.display-none {
  display: none !important;
}
.dropdown-menu.show {
  display: table;
}
.aside {
  background-color: #00aa50;
  box-shadow: 1px 1px 5px rgb(0 0 0 / 10%);
}
.brand {
  background-size: cover;
}
.menu-nav {
  background-color: #00aa50;
}
.aside-menu .menu-nav > .menu-item > .menu-link i,
.aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
  color: #fff !important;
}

.aside-menu .menu-nav > .menu-item {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.aside-menu .menu-nav > .menu-item > .menu-link {
  transition: all 0.5s;
}
.aside-menu .menu-nav > .menu-item.active > .menu-link {
  background-color: #f7931d !important;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.aside-menu
  .menu-nav
  > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.active):hover
  > .menu-link {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.aside-menu .menu-nav {
  padding: 0 0 15px;
}
.aside-menu{
  display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
}
.invert{
  box-shadow: -1px -1px 0 rgb(0 0 0 / 10%) !important;
}
