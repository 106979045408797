.kt-nav {
  display: block;
  padding: 1rem 0;
  margin: 0;
  list-style: none;
  border: 0;
}
.kt-nav .kt-nav__item {
  display: block;
}
.kt-nav .kt-nav__item > .kt-nav__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.55rem 1.75rem;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  font-size: 1rem;
  color: #595d6e;
  font-weight: 400;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 30px;
  flex: 0 0 30px;
  font-size: 1.25rem;
  line-height: 0;
  color: #a2a5b9;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon:before {
  line-height: 0;
}
.kt-nav
  .kt-nav__item
  > .kt-nav__link
  .kt-nav__link-icon.kt-nav__link-icon--center {
  text-align: center;
  margin-right: 0.25rem;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #a2a5b9;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-icon:hover g [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet {
  text-align: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 10px;
  flex: 0 0 10px;
  margin-right: 10px;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-bullet > span {
  vertical-align: middle;
  display: inline-block;
}
.kt-nav
  .kt-nav__item
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  width: 4px;
  height: 4px;
  border-radius: 100%;
}
.kt-nav
  .kt-nav__item
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span {
  width: 8px;
  height: 2px;
  border-radius: 2px;
}
.kt-nav
  .kt-nav__item
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav
  .kt-nav__item
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  background-color: #a2a5b9;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-badge {
  padding: 0px 0px 0px 5px;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow {
  text-align: right;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20px;
  flex: 0 0 20px;
  font-size: 0.8rem;
  color: #a2a5b9;
  font-family: "LineAwesome";
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow:before {
  content: "";
}
.kt-nav .kt-nav__item > .kt-nav__link .kt-nav__link-arrow:before {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow {
  font-family: "LineAwesome";
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow:before {
  content: "";
}
.kt-nav .kt-nav__item > .kt-nav__link.collapsed .kt-nav__link-arrow:before {
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.kt-nav .kt-nav__item > .kt-nav__sub {
  padding: 0;
  margin: 0;
}
.kt-nav .kt-nav__item > .kt-nav__sub .kt-nav__item > .kt-nav__link {
  padding: 0.5rem 1.5rem 0.5rem 2.5rem;
}
.kt-nav .kt-nav__item.kt-nav__item--last {
  padding-bottom: 0;
}
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link,
.kt-nav .kt-nav__item.active > .kt-nav__link,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link {
  background-color: #f7f8fa;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-icon,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-text,
.kt-nav .kt-nav__item.kt-nav__item--active > .kt-nav__link .kt-nav__link-arrow,
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon,
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-text,
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-arrow,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-icon,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-text,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-arrow {
  color: #009870;
}
.kt-nav
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-icon
  g
  [fill],
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon g [fill],
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-icon
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
  fill: #009870;
}
.kt-nav
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-icon:hover
  g
  [fill],
.kt-nav .kt-nav__item.active > .kt-nav__link .kt-nav__link-icon:hover g [fill],
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-icon:hover
  g
  [fill] {
  -webkit-transition: fill 0.3s ease;
  transition: fill 0.3s ease;
}
.kt-nav
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav
  .kt-nav__item.active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav
  .kt-nav__item.active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  background-color: #009870;
}
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed {
  background-color: #f7f8fa;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-icon,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-text,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-arrow {
  color: #009870;
}
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav
  .kt-nav__item:hover:not(.kt-nav__item--disabled).kt-nav__item--sub
  > .kt-nav__link.collapsed
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  background-color: #009870;
}
.kt-nav .kt-nav__item.kt-nav__item--disabled {
  opacity: 0.6;
}
.kt-nav .kt-nav__custom {
  padding: 0.55rem 1.75rem;
}
.kt-nav .kt-nav__section {
  margin-top: 1rem;
  padding: 0 1.75rem;
}
.kt-nav .kt-nav__section .kt-nav__section-icon {
  color: #a2a5b9;
}
.kt-nav .kt-nav__section.kt-nav__section--first {
  margin-top: 0.5rem;
}
.kt-nav .kt-nav__section .kt-nav__section-text {
  color: #009870;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
}
.kt-nav .kt-nav__separator {
  margin: 0.7rem 0;
  height: 0;
  display: block;
  border-bottom: 1px solid #ebedf2;
}
.kt-nav .kt-nav__separator.kt-nav__separator--dashed {
  border-bottom: 1px dashed #ebedf2;
}
.kt-nav .kt-nav__space {
  height: 0;
  margin: 0.5rem 0;
}
.kt-nav .kt-nav__space.kt-nav__space--sm {
  margin: 0.25rem 0;
}
.kt-nav .kt-nav__space.kt-nav__space--lg {
  margin: 0.75rem 0;
}
.kt-nav .kt-nav__space.kt-nav__space--xl {
  margin: 1rem 0;
}
.kt-nav .kt-nav__head,
.kt-nav .kt-nav__foot {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1rem;
  color: #595d6e;
  font-weight: 500;
  padding: 0.55rem 1.75rem;
}
.kt-nav .kt-nav__head > i,
.kt-nav .kt-nav__foot > i {
  font-size: 1.2rem;
  color: #74788d;
}
.kt-nav .kt-nav__head {
  font-size: 1.1rem;
}
.kt-nav.kt-nav--fit {
  padding: 0;
}
.kt-nav.kt-nav--fit .kt-nav__item > .kt-nav__link {
  padding-left: 0;
  padding-right: 0;
}
.kt-nav.kt-nav--lg-space .kt-nav__item {
  margin: 0.35rem 0;
}
.kt-nav.kt-nav--lg-space .kt-nav__item .kt-nav__link {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.kt-nav.kt-nav--lg-space .kt-nav__item:first-child {
  margin-top: 0;
}
.kt-nav.kt-nav--lg-space .kt-nav__item:last-child {
  margin-bottom: 0;
}
.kt-nav.kt-nav--md-space .kt-nav__item {
  margin: 0.25rem 0;
}
.kt-nav.kt-nav--md-space .kt-nav__item .kt-nav__link {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.kt-nav.kt-nav--md-space .kt-nav__item:first-child {
  margin-top: 0;
}
.kt-nav.kt-nav--md-space .kt-nav__item:last-child {
  margin-bottom: 0;
}
.kt-nav.kt-nav--bold .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-weight: 500;
}
.kt-nav.kt-nav--thin .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-weight: 300;
}
.kt-nav.kt-nav--lg-font .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-size: 1.15rem;
}
.kt-nav.kt-nav--xl-font .kt-nav__item .kt-nav__link .kt-nav__link-text {
  font-size: 1.25rem;
}

.kt-nav.kt-nav--v2 .kt-nav__item {
  border-radius: 2rem;
  overflow: hidden;
}
.kt-nav.kt-nav--v2 .kt-nav__item > .kt-nav__link {
  -webkit-transition: none;
  transition: none;
}
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link {
  background-color: #f4f4f9;
  -webkit-transition: none;
  transition: none;
}
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-icon,
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text,
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-arrow {
  color: #009870;
}
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav.kt-nav--v2
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  background-color: #f4f4f9;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active > .kt-nav__link {
  background-color: #009870;
  -webkit-transition: none;
  transition: none;
}
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-icon,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-text,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-arrow {
  color: #ffffff;
}
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  background-color: #ffffff;
}
.kt-nav.kt-nav--v2 .kt-nav__item.kt-nav__item--active:hover > .kt-nav__link {
  background-color: #009870;
}
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-icon,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-text,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-arrow {
  color: #ffffff;
}
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--line
  > span,
.kt-nav.kt-nav--v2
  .kt-nav__item.kt-nav__item--active:hover
  > .kt-nav__link
  .kt-nav__link-bullet.kt-nav__link-bullet--dot
  > span {
  background-color: #ffffff;
}

.kt-nav.kt-nav--v3 .kt-nav__item {
  margin: 0;
}
.kt-nav.kt-nav--v3 .kt-nav__item .kt-nav__link {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: 2px solid transparent;
}
.kt-nav.kt-nav--v3 .kt-nav__item.active .kt-nav__link,
.kt-nav.kt-nav--v3 .kt-nav__item.kt-nav__link--active .kt-nav__link {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #f7f8fa;
  border-left: 2px solid #009870;
}
.kt-nav.kt-nav--v3 .kt-nav__item.active .kt-nav__link .kt-nav__link-text,
.kt-nav.kt-nav--v3
  .kt-nav__item.kt-nav__link--active
  .kt-nav__link
  .kt-nav__link-text {
  color: #009870;
}
.kt-nav.kt-nav--v3 .kt-nav__item:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #f7f8fa;
}
.kt-nav.kt-nav--v3 .kt-nav__item:hover .kt-nav__link .kt-nav__link-text {
  color: #009870;
}

.kt-nav.kt-nav--v4 .kt-nav__item {
  padding: 0.65rem 0;
}
.kt-nav.kt-nav--v4 .kt-nav__item .kt-nav__link {
  background-color: transparent;
  margin-left: 3px;
  color: #9492a1;
  font-size: 1.2rem;
  padding: 0 1.1rem;
}
.kt-nav.kt-nav--v4 .kt-nav__item .kt-nav__link .kt-nav__link-text {
  color: #9492a1;
  font-size: 1.2rem;
}
.kt-nav.kt-nav--v4 .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #009870;
  color: #48465b;
  margin-left: 0;
}
.kt-nav.kt-nav--v4 .kt-nav__item.active .kt-nav__link .kt-nav__link-text {
  color: #48465b;
}
.kt-nav.kt-nav--v4 .kt-nav__item:first-child {
  margin-top: -0.65rem;
}
.kt-nav.kt-nav--v4 .kt-nav__item:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background-color: #f7f8fa;
}
.kt-nav.kt-nav--v4 .kt-nav__item:hover .kt-nav__link .kt-nav__link-text {
  color: #009870;
}

.kt-nav.kt-nav--v4--brand .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #009870;
}

.kt-nav.kt-nav--v4--brand
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #009870;
}

.kt-nav.kt-nav--v4--light .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #ffffff;
}

.kt-nav.kt-nav--v4--light
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #ffffff;
}

.kt-nav.kt-nav--v4--dark .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #282a3c;
}

.kt-nav.kt-nav--v4--dark
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #282a3c;
}

.kt-nav.kt-nav--v4--primary .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #009870;
}

.kt-nav.kt-nav--v4--primary
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #009870;
}

.kt-nav.kt-nav--v4--success .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #1dc9b7;
}

.kt-nav.kt-nav--v4--success
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #1dc9b7;
}

.kt-nav.kt-nav--v4--info .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #5578eb;
}

.kt-nav.kt-nav--v4--info
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #5578eb;
}

.kt-nav.kt-nav--v4--warning .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #ffb822;
}

.kt-nav.kt-nav--v4--warning
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #ffb822;
}

.kt-nav.kt-nav--v4--danger .kt-nav__item.active .kt-nav__link {
  border-left: 3px solid #fd397a;
}

.kt-nav.kt-nav--v4--danger
  .kt-nav__item:hover:not(.kt-nav__item--disabled):not(.kt-nav__item--sub):not(.kt-nav__item--active)
  > .kt-nav__link
  .kt-nav__link-text {
  color: #fd397a;
}
