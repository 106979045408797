.kt-datatable {
  display: none;
}

.kt-datatable.kt-datatable--default {
  display: block;
  margin-bottom: 25px;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table {
  border-collapse: collapse;
  overflow: hidden;
  display: none;
  width: 100%;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot {
  visibility: hidden;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row {
  width: 100%;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell {
  vertical-align: middle;
  padding: 10px;
  font-size: 1rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell:first-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell:first-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell:first-child {
  padding-left: 25px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell:last-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell:last-child,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell:last-child {
  padding-right: 25px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left {
  text-align: left;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--left
  > span {
  text-align: left;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right {
  text-align: right;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span {
  text-align: right;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--right
  > span
  > i {
  right: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center {
  text-align: center;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--center
  > span {
  text-align: center;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort {
  cursor: pointer;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort
  i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort
  i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sort
  i {
  font-size: 0.6rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--resizing,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--resizing,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--resizing {
  cursor: col-resize;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check {
  text-align: center;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span {
  overflow: visible;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox {
  top: 2px;
  padding: 0;
  margin: 0 0 0 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > span {
  width: 12px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail {
  display: inline-block;
  text-align: right;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail
  > i,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__toggle-detail
  > .kt-datatable__toggle-detail
  > i {
  font-size: 1.4rem;
  width: 12px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown {
  display: inline-block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu {
  margin-top: 0.5rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown.dropup
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown.dropup
  .dropdown-menu,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown.dropup
  .dropdown-menu {
  margin-top: auto;
  margin-bottom: 0.5rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-right,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-right {
  margin-right: -0.8rem;
  /*rtl:ignore*/
  margin-left: 65px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-left,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .dropdown
  .dropdown-menu.dropdown-menu-left {
  margin-left: -10rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  > .kt-datatable__lock,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  > .kt-datatable__lock,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  > .kt-datatable__lock {
  border: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  font-weight: 500;
  vertical-align: middle;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell {
  padding: 16px 10px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span
  > i {
  display: inline-block;
  position: relative;
  right: -10px;
  line-height: 0;
  vertical-align: middle;
  font-size: 1.1rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body.ps {
  padding-bottom: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell {
  font-weight: regular;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--check
  > span
  > .kt-checkbox {
  top: 1px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail {
  display: block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail {
  display: block;
  padding: 0 60px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row {
  display: table-row;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell {
  padding: 8px 12px;
  text-align: left;
  vertical-align: top;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  width: auto !important;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell:first-child {
  font-weight: 500;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  .kt-datatable__row
  > .kt-datatable__cell:last-child {
  font-weight: regular;
  padding-left: 20px;
}
.kt-datatable.kt-datatable--default.kt-datatable--error .kt-datatable__body {
  padding: 30px;
  text-align: center;
}
.kt-datatable.kt-datatable--default.kt-datatable--lock
  > .kt-datatable__table
  > .kt-datatable__head
  > .kt-datatable__lock,
.kt-datatable.kt-datatable--default.kt-datatable--lock
  > .kt-datatable__table
  > .kt-datatable__body
  > .kt-datatable__lock,
.kt-datatable.kt-datatable--default.kt-datatable--lock
  > .kt-datatable__table
  > .kt-datatable__foot
  > .kt-datatable__lock {
  display: inline-block;
  position: relative;
  vertical-align: top;
  overflow: hidden;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded {
  display: block;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table {
  display: block;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__head,
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__body,
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__foot {
  visibility: visible;
  position: relative;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row,
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row,
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row {
  table-layout: initial;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table {
  display: block;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__head,
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__foot {
  overflow: hidden;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row,
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row {
  position: relative;
}
.kt-datatable.kt-datatable--default.kt-datatable--scroll
  > .kt-datatable__table
  > .kt-datatable__body {
  overflow: hidden;
}
.kt-datatable.kt-datatable--default > .kt-datatable__pager {
  margin: 0px;
  padding: 25px;
  padding-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager.kt-datatable__pager--top {
  margin-bottom: 20px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li {
  padding: 0;
  margin-right: 5px;
  display: inline-block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li:last-child {
  margin-right: 0;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  border-radius: 3px;
  position: relative;
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 500;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link
  > i {
  font-size: 0.6rem;
  text-align: center;
  display: inline-block;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--more-prev,
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--more-next {
  font-weight: 600;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-pager-input {
  height: 2.25rem;
  width: 3.5rem;
  text-align: center;
  border-radius: 3px !important;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size {
  margin-right: 10px;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle {
  height: 2.25rem;
  padding: 0.45rem 1rem;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .dropdown-menu.inner
  > li.selected
  > a
  span.check-mark {
  margin-top: -0.6rem;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table {
  margin-bottom: 0;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__toggle-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__toggle-subtable {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__toggle-subtable
  > i,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable
  > i,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__toggle-subtable
  > i {
  line-height: 0;
  font-size: 1.4rem;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__toggle-subtable:hover,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable:hover,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__toggle-subtable:hover {
  text-decoration: none;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row-subtable {
  display: table;
  width: 100%;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row-subtable
  > .kt-datatable__subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable
  > .kt-datatable__subtable,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row-subtable
  > .kt-datatable__subtable {
  padding: 20px;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__pager,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__pager,
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__pager {
  padding-top: 10px;
  padding-bottom: 10px;
}

@media (max-width: 768px) {
  .kt-datatable.kt-datatable--default {
    margin-bottom: 15px;
  }
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__head
    .kt-datatable__row
    > .kt-datatable__cell:first-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__body
    .kt-datatable__row
    > .kt-datatable__cell:first-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__foot
    .kt-datatable__row
    > .kt-datatable__cell:first-child {
    padding-left: 15px;
  }
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__head
    .kt-datatable__row
    > .kt-datatable__cell:last-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__body
    .kt-datatable__row
    > .kt-datatable__cell:last-child,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__table
    > .kt-datatable__foot
    .kt-datatable__row
    > .kt-datatable__cell:last-child {
    padding-right: 15px;
  }
  .kt-datatable.kt-datatable--default > .kt-datatable__pager {
    margin: 0;
    padding: 15px 15px 7.5px 15px;
  }
  .kt-datatable.kt-datatable--default
    > .kt-datatable__pager
    .kt-datatable__pager-nav,
  .kt-datatable.kt-datatable--default
    > .kt-datatable__pager
    .kt-datatable__pager-info {
    margin-top: 10px;
  }
}

.kt-datatable > .kt-datatable__table {
  background-color: #fff;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  > .kt-datatable__lock.kt-datatable__lock--left,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  > .kt-datatable__lock.kt-datatable__lock--left,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  > .kt-datatable__lock.kt-datatable__lock--left {
  z-index: 1;
  -webkit-box-shadow: 0px 0px 17px 5px rgba(113, 106, 202, 0.14);
  box-shadow: 0px 0px 17px 5px rgba(113, 106, 202, 0.14);
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  > .kt-datatable__lock.kt-datatable__lock--right,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  > .kt-datatable__lock.kt-datatable__lock--right,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  > .kt-datatable__lock.kt-datatable__lock--right {
  -webkit-box-shadow: 0px 0px 17px 5px rgba(113, 106, 202, 0.14);
  box-shadow: 0px 0px 17px 5px rgba(113, 106, 202, 0.14);
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  > .kt-datatable__lock.kt-datatable__lock--scroll,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  > .kt-datatable__lock.kt-datatable__lock--scroll,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  > .kt-datatable__lock.kt-datatable__lock--scroll {
  position: relative;
}
.kt-datatable > .kt-datatable__table > .kt-datatable__head .kt-datatable__row,
.kt-datatable > .kt-datatable__table > .kt-datatable__body .kt-datatable__row,
.kt-datatable > .kt-datatable__table > .kt-datatable__foot .kt-datatable__row {
  border-bottom: 1px solid #ebedf3;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > span {
  background: #ebedf3;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > input:checked
  ~ span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > input:checked
  ~ span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  .kt-checkbox.kt-checkbox--solid
  > input:checked
  ~ span {
  background: #e8ecfa;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-detail {
  vertical-align: middle;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-detail
  i {
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #498c70;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-detail
  i:before {
  line-height: 0;
  vertical-align: middle;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-detail:hover
  i,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-detail.kt-datatable__toggle-detail--active
  i {
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
  color: #498c70;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-detail
  .kt-datatable__detail
  table {
  border-left: 1px solid #ebedf3;
  border-right: 1px solid #ebedf3;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell {
  background: transparent;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span,
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  color: #595d6e;
}
.kt-datatable
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell
  > span
  > i {
  color: #498c70;
}

.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link {
  color: #498c70;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--first,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--prev,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--next,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--last {
  background: #ebedf3;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--first:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--prev:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--next:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--last:hover {
  background: #498c70;
  color: #ffffff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link:hover {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #498c70;
  color: #ffffff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--active {
  background: #498c70;
  color: #ffffff;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--disabled,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-datatable__pager-link.kt-datatable__pager-link--disabled:hover {
  color: #498c70;
  background: #ebedf3;
  opacity: 0.3;
}

.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-pager-input {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #ebedf3;
  border-color: #ebedf3;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-nav
  > li
  > .kt-pager-input:focus {
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #498c70;
  border-color: #498c70;
}

.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle {
  font-size: 1rem;
  font-weight: 500;
  border: 0 !important;
  color: #498c70;
  background: #ebedf3;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle
  i {
  color: #ffffff;
}

.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle:focus,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle:hover,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size.show
  > .btn.dropdown-toggle {
  border: 0 !important;
  color: #ffffff !important;
  background: #498c70;
}
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle:focus
  i,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .btn.dropdown-toggle:hover
  i,
.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size.show
  > .btn.dropdown-toggle
  i {
  color: #ffffff !important;
}

.kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .dropdown-menu {
  z-index: 100;
}
.modal
  .kt-datatable
  > .kt-datatable__pager
  > .kt-datatable__pager-info
  .kt-datatable__pager-size
  .dropdown-menu {
  z-index: 1051;
}

.kt-datatable.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__toggle-subtable
  > i {
  color: #498c70;
}

.kt-datatable.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable {
  border-top: 0;
}

.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell {
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell
  > span {
  color: #595d6e;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--hover {
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: transparent;
}

.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--even
  > .kt-datatable__cell {
  background: transparent;
}

.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--active
  > .kt-datatable__cell {
  background: #f7f8fa;
}

.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--hover:not(.kt-datatable__row--active) {
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
}
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--hover:not(.kt-datatable__row--active)
  > .kt-datatable__cell {
  -webkit-transition: background 0.3s ease;
  transition: background 0.3s ease;
  background: transparent;
}

.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__head
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sorted
  > span,
.kt-datatable.kt-datatable--default
  > .kt-datatable__table
  > .kt-datatable__foot
  .kt-datatable__row
  > .kt-datatable__cell.kt-datatable__cell--sorted
  > span {
  color: #498c70;
}

.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row.kt-datatable__row--subtable-expanded
  > .kt-datatable__cell {
  background: #f7f8fa !important;
}

.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable {
  background: transparent;
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable
  > .kt-datatable__subtable
  > .kt-datatable {
  -webkit-box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.1);
  box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.1);
}
.kt-datatable.kt-datatable--default.kt-datatable--subtable
  > .kt-datatable__table
  > .kt-datatable__body
  .kt-datatable__row-subtable.kt-datatable__row-loading
  > .kt-datatable__subtable
  > .kt-datatable
  > .kt-datatable__table {
  background: transparent;
}

.kt-datatable .ps > .ps__rail-y > .ps__thumb-y,
.kt-datatable .ps > .ps__rail-x > .ps__thumb-x {
  background: #dbe2f7;
}
.kt-datatable .ps > .ps__rail-y > .ps__thumb-y:hover,
.kt-datatable .ps > .ps__rail-y > .ps__thumb-y:focus,
.kt-datatable .ps > .ps__rail-x > .ps__thumb-x:hover,
.kt-datatable .ps > .ps__rail-x > .ps__thumb-x:focus {
  background: #dbe2f7;
}
.kt-datatable.kt-datatable--default.kt-datatable--loaded
  > .kt-datatable__table {
  display: table !important;
}
.kt-datatable__pager-link--disabled a,
.kt-datatable__pager-link--disabled > a:hover {
  color: #498c70 !important;
  background: #ebedf3 !important;
  opacity: 0.3 !important;
}
.kt-datatable.kt-datatable--default > .kt-datatable__table {
  overflow: auto;
}
